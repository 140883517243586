<template>
  <v-dialog
    v-model="modalOpen"
    max-width="630px"
    class="AddEditWebhookModal">
    <v-card outlined>
      <v-card-title class="AddEditWebhookModal__title">
        <v-row>
          <v-col
            cols="auto"
            class="mr-auto">
            {{ isEdit ? 'Edit Webhook' : 'Add new Webhook' }}
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              @click="close">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="AddEditWebhookModal__content">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Webhook Name">
                  <v-text-field
                    label="Webhook Name"
                    name="webhookName"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.name"
                    :loading="addUpdateWebhookLoading"
                    :disabled="addUpdateWebhookLoading" />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Link">
                  <v-text-field
                    label="Link"
                    name="link"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.link"
                    :loading="addUpdateWebhookLoading"
                    :disabled="addUpdateWebhookLoading" />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Trigger Type">
                  <v-select
                    label="Trigger Type"
                    name="trigger_type"
                    :items="triggerTypes"
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.trigger_type"
                    :loading="addUpdateWebhookLoading"
                    :disabled="addUpdateWebhookLoading"></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="addUpdateWebhookLoading">
                  {{ isEdit ? 'Edit Webhook' : 'Add Webhook' }}
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: webhooksGetters, mapActions: webhooksActions } =
  createNamespacedHelpers('webhooks');

const { mapGetters: notificationsGetters } =
  createNamespacedHelpers('notifications');

export default {
  name: 'AddEditWebhookModal',
  props: {
    webhookId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...webhooksGetters({
      webhooks: 'WEBHOOKS',
      triggerTypes: 'WEBHOOKS_TRIGGERS',
      addUpdateWebhookLoading: 'ADD_UPDATE_WEBHOOK_LOADING',
    }),
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isEdit() {
      return Boolean(this.webhookId);
    },
  },
  methods: {
    ...webhooksActions([
      'fetchWebhookDetails',
      'createWebhook',
      'updateWebhook',
    ]),
    close() {
      this.modalOpen = false;
    },
    onSubmit() {
      if (this.isEdit) {
        this.updateWebhook({
          id: this.webhookId,
          webhookData: {
            name: this.formData.name,
            link: this.formData.link,
            trigger_type: this.formData.trigger_type,
          },
        });
      } else {
        this.createWebhook({
          name: this.formData.name,
          link: this.formData.link,
          trigger_type: this.formData.trigger_type,
        });
      }
    },
  },
  watch: {
    addUpdateWebhookLoading(value) {
      if (!value && !this.hasError) {
        this.close();
      }
    },
  },
  async mounted() {
    if (this.webhookId) {
      const webhook = this.webhooks.find(
        (webhook) => webhook.id === Number(this.webhookId)
      );

      this.formData = { ...webhook };
    }
  },
};
</script>

<style lang="scss" scoped>
.AddEditWebhookModal {
  &__title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__content {
    padding-top: 20px !important;
  }
}
</style>
