<template>
  <div class="Webhooks">
    <v-row class="Webhooks__filter-row">
      <v-col>
        <v-btn
          text
          small
          color="primary"
          @click="openAddEditWebhook()">
          + Add Webhook
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="Webhooks__data-table elevation-0"
      :items="webhooks"
      :headers="headers"
      item-key="id"
      outlined
      :loading="webhooksLoading">
      <template #item.actions="{ item }">
        <v-btn
          icon
          color="primary"
          @click.stop="openAddEditWebhook(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          color="error"
          @click.stop="removeWebhook(item)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <add-edit-webhook-modal
      v-model="showAddEditModal"
      :webhook-id="selectedWebhookId"
      v-if="showAddEditModal" />
    <delete-prompt-modal
      v-model="showDeletePrompt"
      :webhook-id="selectedWebhookId"
      v-if="showDeletePrompt" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import AddEditWebhookModal from './modals/add-edit-webhook-modal.vue';
import DeletePromptModal from './modals/delete-prompt-modal.vue';

const { mapActions: webhooksActions, mapGetters: webhooksGetters } =
  createNamespacedHelpers('webhooks');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'Webhooks',
  components: {
    DeletePromptModal,
    AddEditWebhookModal,
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Link', value: 'link' },
        {
          text: 'Trigger Action',
          value: 'trigger_type',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
      ],
      selectedWebhookId: '',
      showAddEditModal: false,
      showDeletePrompt: false,
    };
  },
  methods: {
    ...webhooksActions([
      'fetchWebhooks',
      'deleteWebhook',
      'fetchWebhooksTriggerTypes',
    ]),
    openAddEditWebhook(item) {
      this.selectedWebhookId = item?.id;
      this.showAddEditModal = true;
    },
    removeWebhook(item) {
      this.selectedWebhookId = item.id;
      this.showDeletePrompt = true;
    },
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchWebhooks();
    },
  },
  computed: {
    ...webhooksGetters({
      webhooks: 'WEBHOOKS',
      webhooksLoading: 'WEBHOOKS_LOADING',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    is_privileged() {
      return this.user && this.user.is_privileged;
    },
    sh_admin() {
      return this.customer.customer_id === 0;
    },
  },
  mounted() {
    this.fetchWebhooksTriggerTypes();
    this.fetchWebhooks();
  },
};
</script>

<style lang="scss" scoped>
@import './webhooks';
</style>
