<template>
  <v-dialog v-model="modalOpen" max-width="315px" class="DeletePromptModal">
    <v-card outlined>
      <v-card-title class="DeletePromptModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            Delete Webhook
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="DeletePromptModal__content">
        <p>
          Are you sure you want to delete this webhook?
        </p>
        <v-row>
          <v-col>
            <v-btn
              plain
              @click="onCancel"
              :disabled="removeWebhookLoading"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click="onDelete"
              :disabled="removeWebhookLoading"
              :loading="removeWebhookLoading"
            >
              Delete
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: webhooksGetters, mapActions: webhooksActions } =
  createNamespacedHelpers("webhooks");

const { mapGetters: notificationsGetters } = createNamespacedHelpers('notifications');

export default {
  name: 'DeletePromptModal',
  props: {
    webhookId: {
      type: Number,
      required: true
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...webhooksGetters({
      removeWebhookLoading: 'REMOVE_WEBHOOK_LOADING',
    }),
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...webhooksActions(["deleteWebhook"]),
    onDelete() {
      this.deleteWebhook({ id: this.webhookId });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.modalOpen = false;
    }
  },
  watch: {
    removeWebhookLoading(value) {
      if (!value && !this.hasError) {
        this.close();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.DeletePromptModal {
  &__title {
    background: #F8F9FA;
    border-bottom: thin solid #DDE2E5;
  }

  &__content {
    padding-top: 20px !important;
  }
}

</style>
